/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
body {
  background-color: #00B8C7;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.mat-checkbox-layout { white-space: normal !important; }

.spacer {
  flex: 1 1 auto;
}
